import React from 'react';

const SplashScreen = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gradient-to-t from-[#d93953] to-[#f48432] flex flex-col items-center justify-center z-50">
      <div className="flex flex-col items-center space-y-6">
        {/* Loading Spinner */}
        <div className="animate-spin w-16 h-16 border-t-4 border-white border-solid rounded-full"></div>

        {/* Loading Text */}
        <div className="text-white text-lg font-semibold">Loading...</div>

        {/* Powered By Section */}
        <div className="text-center mt-10">
          <p className="text-white text-sm">powered by</p>
          <p className="text-white text-4xl font-bold">Xpoerience</p>
          <p className="text-white text-sm">event technology experts</p>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
